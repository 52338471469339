/*
 * 外包管理接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例

// 分页查询外包服务商
export const selectOutsourcingProviderByPagination = (param) => {
  return axios.post(`/wlapi/knOutsourcingProvider/selectOutsourcingProviderByPagination`, param);
}
// 添加或修改外包服务商
export const addOrUpdateOutsourcingProvider = (param) => {
  return axios.post(`/wlapi/knOutsourcingProvider/addOrUpdateOutsourcingProvider`, param);
}
// 查询外包服务商详情
export const selectOutsourcingProviderById = (param) => {
  return axios.get(`/wlapi/knOutsourcingProvider/selectOutsourcingProviderById?id=${param}`);
}
// 删除外包服务商
export const deleteOutsourcingProvider = (param) => {
  return axios.post(`/wlapi/knOutsourcingProvider/deleteOutsourcingProvider`, param);
}

// 分页查询外包服务人员
export const selectOutsourcingPersonalByPagination = (param) => {
  return axios.post(`/wlapi/knOutsourcingPersonal/selectOutsourcingPersonalByPagination`, param);
}
// 添加或修改外包服务人员
export const addOrUpdateOutsourcingPersonal = (param) => {
  return axios.post(`/wlapi/knOutsourcingPersonal/addOrUpdateOutsourcingPersonal`, param);
}
// 查询外包服务人员详情
export const selectOutsourcingPersonalById = (param) => {
  return axios.get(`/wlapi/knOutsourcingPersonal/selectOutsourcingPersonalById?id=${param}`);
}
// 删除外包服务人员
export const deleteOutsourcingPersonal = (param) => {
  return axios.post(`/wlapi/knOutsourcingPersonal/deleteOutsourcingPersonal`, param);
}