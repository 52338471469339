import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, reactive, onMounted } from 'vue';
import useTablePagination from '@/hooks/pagination';
import Cookies from "js-cookie";
import upload from '@/components/upload.vue';
import getFieldRules from '@/utils/fieldRules';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import { selectOutsourcingProviderByPagination, addOrUpdateOutsourcingProvider, selectOutsourcingProviderById, deleteOutsourcingProvider, selectOutsourcingPersonalByPagination, addOrUpdateOutsourcingPersonal, selectOutsourcingPersonalById, deleteOutsourcingPersonal } from '@/api/outsourcing'; //表头

const columns = [{
  title: '公司名称',
  dataIndex: 'companyName'
}, {
  title: '法人',
  dataIndex: 'legalPerson'
}, {
  title: '联系人',
  dataIndex: 'contact'
}, {
  title: '联系方式',
  dataIndex: 'contactPhone'
}, {
  title: '创建时间',
  dataIndex: 'createTime'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
const columnsRen = [{
  title: '姓名',
  dataIndex: 'personalName'
}, {
  title: '联系方式',
  dataIndex: 'contactPhone'
}, {
  title: '身份证号',
  dataIndex: 'idCard'
}, {
  title: '创建时间',
  dataIndex: 'createTime'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default {
  components: {
    upload,
    QuestionCircleOutlined
  },

  setup() {
    const activeKey = ref('1');

    const handleTab = key => {
      if (key == '2') {
        formRef.value.resetFields();
        getRenTableData().then(res => {
          if (res) {
            activeKey.value = key;
          }
        });
      } else {
        formRefRen.value.resetFields();
        getTableData().then(res => {
          if (res) {
            activeKey.value = key;
          }
        });
      } // activeKey.value = key
      // getRenTableData().then(res => console.log(res))

    }; ////////外包服务商


    const formRef = ref();
    const formState = reactive({
      companyName: null,
      contact: null
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        companyName: formState.companyName,
        contact: formState.contact
      };
      loading.value = true;

      try {
        let res = await selectOutsourcingProviderByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
          return true;
        } else {
          loading.value = false;

          _message.error(res.message);

          return false;
        }
      } catch {
        loading.value = false;
        return false;
      }
    }; //新建


    const visible = ref(false);
    const modalTitle = ref('新建');
    const shangDisabled = ref(true);

    const handleAdd = () => {
      visible.value = true;
      modalTitle.value = '新建';
      shangDisabled.value = false;
      formStateForModel.id = null;
      formStateForModel.companyName = null;
      formStateForModel.legalPerson = null;
      formStateForModel.contact = null;
      formStateForModel.contactPhone = null;
      formStateForModel.businessLicenseImg = null;
      formStateForModel.contractScope = null;
      formStateForModel.paymentMethod = null;
      formStateForModel.otherCard = [];
    };

    const formRefForModel = ref();
    const formStateForModel = reactive({
      id: null,
      companyName: null,
      legalPerson: null,
      contact: null,
      contactPhone: null,
      businessLicenseImg: null,
      contractScope: null,
      paymentMethod: null,
      otherCard: []
    });
    const {
      checkName,
      checkPhone,
      checkCard
    } = getFieldRules();
    const rules = {
      legalPerson: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      contact: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      contactPhone: [{
        required: true,
        validator: checkPhone,
        trigger: 'blur'
      }],
      companyName: [{
        required: true,
        message: '公司名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      idCard: [{
        required: true,
        validator: checkCard,
        trigger: 'blur'
      }],
      personalName: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }]
    };
    const adLoading = ref(false);

    const headerImgUrl = val => {
      // console.log(11112222,val)
      formStateForModel.businessLicenseImg = val;
    };

    const handleCancel = () => {
      formRefForModel.value.resetFields();
      visible.value = false;
    };

    const toView = (val, num) => {
      getDetail(val).then(res => {
        if (res) {
          if (num === 0) {
            visible.value = true;
            modalTitle.value = '详情';
            shangDisabled.value = true;
          } else {
            visible.value = true;
            modalTitle.value = '修改';
            shangDisabled.value = false;
          }
        }
      });
    };

    const getDetail = async id => {
      try {
        let res = await selectOutsourcingProviderById(id);

        if (res.code == 200) {
          Object.assign(formStateForModel, res.data);
          formStateForModel.otherCard = res.data.otherCard ? JSON.parse(formStateForModel.otherCard) : [];
          formStateForModel.otherCard = formStateForModel.otherCard.filter(item => item.otherCardName != null);
          return true;
        } else {
          _message.error(res.message);

          return false;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    };

    const handleOk = () => {
      formRefForModel.value.validate().then(async () => {
        adLoading.value = true;

        try {
          let arr = [];

          if (formStateForModel.otherCard.length) {
            arr = [];
            formStateForModel.otherCard.forEach(item => {
              if (item.otherCardUrl) {
                arr.push(item);
              }
            });
          }

          let param = {};
          Object.assign(param, formStateForModel);
          param.otherCard = JSON.stringify(param.otherCard);
          let res = await addOrUpdateOutsourcingProvider(param);

          if (res.code === 200) {
            _message.success(res.message);

            adLoading.value = false;
            formRefForModel.value.resetFields();
            visible.value = false;
            getTableData();
          } else {
            _message.error(res.message);

            adLoading.value = false;
          }
        } catch (error) {
          console.log(error);
          adLoading.value = false;
        }
      });
    };

    const onDelete = async id => {
      let res = await deleteOutsourcingProvider({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    }; ////////外包服务人员


    const formRefRen = ref();
    const formStateRen = reactive({
      personalName: null,
      idCard: null
    }); //点击查询

    const onSubmitRen = () => {
      current.value = 1;
      getRenTableData();
    }; //查询条件重置


    const resetFormRen = () => {
      formRefRen.value.resetFields();
      current.value = 1;
      getRenTableData();
    }; //表格


    const renTableData = ref([]);
    const loadingRen = ref(false); //点击分页

    const onPageChangeRen = pagination => {
      current.value = pagination.current;
      getRenTableData();
    };

    const getRenTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        personalName: formStateRen.personalName,
        idCard: formStateRen.idCard
      };
      loadingRen.value = true;

      try {
        let res = await selectOutsourcingPersonalByPagination(param);

        if (res.code === 200) {
          const arr = [];
          renTableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(renTableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loadingRen.value = false;
          return true;
        } else {
          loadingRen.value = false;

          _message.error(res.message);

          return false;
        }
      } catch {
        loadingRen.value = false;
        return false;
      }
    }; //新建


    const visibleRen = ref(false);
    const modalTitleRen = ref('新建');
    const renDisabled = ref(true);

    const handleAddRen = () => {
      visibleRen.value = true;
      modalTitleRen.value = '新建';
      renDisabled.value = false;
      formStateForModelRen.id = null;
      formStateForModelRen.otherCard = [];
      formStateForModelRen.personalName = null;
      formStateForModelRen.contactPhone = null;
      formStateForModelRen.idCard = null;
      formStateForModelRen.passNo = null;
      formStateForModelRen.idCardImg = null;
      formStateForModelRen.idCardBack = null;
    };

    const formRefForModelRen = ref();
    const formStateForModelRen = reactive({
      otherCard: [],
      personalName: null,
      contactPhone: null,
      idCard: null,
      passNo: null,
      idCardImg: null,
      idCardBack: null,
      id: null
    });
    const adRenLoading = ref(false);

    const headerImgUrlRen = val => {
      // console.log(11112222,val)
      formStateForModelRen.idCardImg = val;
    };

    const headerImgUrlRen1 = val => {
      // console.log(11112222,val)
      formStateForModelRen.idCardBack = val;
    };

    const handleCancelRen = () => {
      formRefForModelRen.value.resetFields();
      visibleRen.value = false;
    };

    const toViewRen = (val, num) => {
      getRenDetail(val).then(res => {
        if (res) {
          if (num === 0) {
            visibleRen.value = true;
            modalTitleRen.value = '详情';
            renDisabled.value = true;
          } else {
            visibleRen.value = true;
            modalTitleRen.value = '修改';
            renDisabled.value = false;
          }
        }
      });
    };

    const getRenDetail = async id => {
      try {
        let res = await selectOutsourcingPersonalById(id);

        if (res.code == 200) {
          Object.assign(formStateForModelRen, res.data);
          formStateForModelRen.otherCard = JSON.parse(formStateForModelRen.otherCard);
          formStateForModelRen.otherCard = formStateForModelRen.otherCard.filter(item => item.otherCardName != null);
          return true;
        } else {
          _message.error(res.message);

          return false;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    };

    const handleOkRen = () => {
      formRefForModelRen.value.validate().then(async () => {
        let arr = [];

        if (formStateForModelRen.otherCard.length) {
          arr = [];
          formStateForModelRen.otherCard.forEach(item => {
            if (item.otherCardUrl) {
              arr.push(item);
            }
          });
        } // if(!arr.length) {
        //   arr.push({
        //     otherCardUrl:null,
        //     otherCardName:null
        //   })
        // }


        let param = {};
        Object.assign(param, formStateForModelRen);
        param.otherCard = JSON.stringify(param.otherCard);
        adRenLoading.value = true;

        try {
          let res = await addOrUpdateOutsourcingPersonal(param);

          if (res.code === 200) {
            _message.success(res.message);

            adRenLoading.value = false;
            formRefForModelRen.value.resetFields();
            visibleRen.value = false;
            getRenTableData();
          } else {
            _message.error(res.message);

            adRenLoading.value = false;
          }
        } catch (error) {
          console.log(error);
          adRenLoading.value = false;
        }
      });
    }; //新增删除其他证件


    const addOtherCard = () => {
      formStateForModelRen.otherCard.push({
        otherCardUrl: null,
        otherCardName: null
      });
    };

    const deleteOtherCard = index => {
      formStateForModelRen.otherCard.splice(index, 1);
    };

    const otherCardUrl = (val, index) => {
      console.log(11112222, val, index);
      formStateForModelRen.otherCard[index].otherCardUrl = val;
    };

    const onDeleteRen = async id => {
      let res = await deleteOutsourcingPersonal({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);

        getRenTableData();
      } else {
        _message.error(res.message);
      }
    }; //新增删除其他证件


    const addWaiOtherCard = () => {
      formStateForModel.otherCard.push({
        otherCardUrl: null,
        otherCardName: null
      });
    };

    const deleteWaiOtherCard = index => {
      formStateForModel.otherCard.splice(index, 1);
    };

    const waiOtherCardUrl = (val, index) => {
      formStateForModel.otherCard[index].otherCardUrl = val;
    };

    onMounted(() => {
      getTableData();
    });
    return {
      activeKey,
      handleTab,
      columns,
      formRef,
      formState,
      onSubmit,
      resetForm,
      tableData,
      loading,
      paginationConfig,
      onPageChange,
      handleAdd,
      visible,
      modalTitle,
      formRefForModel,
      handleCancel,
      formStateForModel,
      rules,
      adLoading,
      headerImgUrl,
      shangDisabled,
      toView,
      handleOk,
      onDelete,
      formRefRen,
      formStateRen,
      onSubmitRen,
      resetFormRen,
      renTableData,
      loadingRen,
      onPageChangeRen,
      columnsRen,
      visibleRen,
      modalTitleRen,
      renDisabled,
      handleAddRen,
      formRefForModelRen,
      formStateForModelRen,
      adRenLoading,
      headerImgUrlRen,
      headerImgUrlRen1,
      handleCancelRen,
      toViewRen,
      handleOkRen,
      addOtherCard,
      deleteOtherCard,
      otherCardUrl,
      onDeleteRen,
      addWaiOtherCard,
      deleteWaiOtherCard,
      waiOtherCardUrl
    };
  }

};